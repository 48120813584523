import { makeStyles, Typography } from "@material-ui/core"
import { motion } from "framer-motion"
import { FormattedMessage } from "gatsby-plugin-intl"
import React, { FC } from "react"

export interface ContactSuccessNotificationProps {
  sent?: boolean
}

const usecss = makeStyles({
  ctaButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
})

export const ContactSuccessNotification: FC<ContactSuccessNotificationProps> = ({
  sent,
}) => {
  const classes = usecss()

  if (!sent) {
    return null
  }

  return (
    <motion.div
      animate={{ scaleY: [0, 1], translateY: [0, 30] }}
      className={classes.ctaButtonContainer}
    >
      <Typography align="center" variant="body1">
        <FormattedMessage
          defaultMessage="Thanks for writing us, we will contact you as soon as possible"
          id="components.ContactSuccessNotification.thanksMessage"
        />
      </Typography>
    </motion.div>
  )
}
