import { Container, makeStyles } from "@material-ui/core"
import { CustomTheme } from "@theme"
import React, { FC } from "react"

export interface ContactContainerProps {}

const usecss = makeStyles((theme: CustomTheme) => ({
  container: {
    paddingTop: theme.custom.sectionSpacingFluid,
    paddingBottom: theme.custom.sectionSpacingFluid,
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: "url(/images/pattern-bg.svg)",
    backgroundSize: "cover",
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.custom.sectionSpacingLg,
      paddingBottom: theme.custom.sectionSpacingLg,
    },
  },
  intro: {
    color: "white",
  },
}))

export const ContactContainer: FC<ContactContainerProps> = ({ children }) => {
  const classes = usecss()

  return (
    <section className={classes.container}>
      <Container maxWidth="sm" className={classes.intro}>
        {children as any}
      </Container>
    </section>
  )
}
