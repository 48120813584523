import { either } from "tiinvo"
import * as client from "./client"

const apiclient = client.create(client.baseURL("/api"))

const subscriberequest = (data: {
  name: string
  email: string
  message: string
}) => apiclient.post("/getintouch", data)

const subscribehandler = client.mapresponse<Error, boolean>({
  200: () => either.right(true),
  400: either.left,
  500: either.left,
})

export const subscribe = client.withstatushandler(subscribehandler)(
  subscriberequest
)
