import { Button, CircularProgress, makeStyles } from "@material-ui/core"
import { motion } from "framer-motion"
import { FormattedMessage } from "gatsby-plugin-intl"
import React, { FC } from "react"
import { FnBase } from "tiinvo"

export interface ContactCTAProps {
  disabled?: boolean
  submitting?: boolean
  onSubmit?: FnBase
}

const usecss = makeStyles({
  ctaButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  ctaButton: {
    marginTop: 25,
  },
})

export const ContactCTA: FC<ContactCTAProps> = ({
  disabled,
  onSubmit,
  submitting,
}) => {
  const classes = usecss()

  return (
    <motion.div className={classes.ctaButtonContainer}>
      <Button
        className={classes.ctaButton}
        color="primary"
        disabled={disabled}
        onClick={onSubmit}
        variant="contained"
        size="large"
      >
        {submitting && (
          <CircularProgress
            color="secondary"
            style={{ position: "absolute" }}
          />
        )}
        <FormattedMessage
          defaultMessage="Send Message"
          id="components.ContactCTA.ctaLabel"
        />
      </Button>
    </motion.div>
  )
}
