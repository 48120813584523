import { makeStyles, Typography } from "@material-ui/core"
import { CustomTheme } from "@theme"
import { FormattedMessage } from "gatsby-plugin-intl"
import React, { FC } from "react"

export interface ContactTitleProps {}

const usecss = makeStyles((theme: CustomTheme) => {
  return {
    title: {
      color: theme.custom.lightHeadingTextColor,
    },
  }
})

export const ContactTitle: FC<ContactTitleProps> = ({}) => {
  const classes = usecss()

  return (
    <Typography
      variant="h1"
      component="h1"
      align="center"
      className={classes.title}
    >
      <FormattedMessage
        defaultMessage="Get in Touch"
        id="components.ContactTitle.title"
      />
    </Typography>
  )
}
